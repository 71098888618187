import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Docker removing unused images and container",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:54:36.000Z",
  "url": "/2018/02/docker-removing-unused-images-and-container/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`While learning docker and following through dozens of tutorials around the web, the amount of new images I downloaded and containers I created can easily be above the 50-60 scale`}{`…`}</p>
    <p>{`The problem with that, is that most of them are not used!`}</p>
    <p>{`most of them have the annoying `}{`“`}{`”`}{` in the image name, and all the containers are stopped.`}</p>
    <p>{`I decided to start clearing stuff, so even though I would be happy to go one by one`}{`…`}{` I decided to batch the process, and here the 2 tiny commands that could help you as well.`}</p>
    <p>{`remove all stopped containers:`}</p>
    <pre><code parentName="pre" {...{}}>{`docker rm $(docker ps -a | grep -v Up | awk '{print $1}')
`}</code></pre>
    <p>{`how it works?`}</p>
    <ul>
      <li parentName="ul">{`”`}{` docker ps -a `}{`”`}{` `}{`–`}{` lists all containers`}</li>
      <li parentName="ul">{`”`}{` grep -v Up `}{`”`}{` select only the rows where the container is not `}{`“`}{`Up`}{`”`}</li>
      <li parentName="ul">{`”`}{` awk `}{`‘`}{`{print $1}`}{`’`}{` `}{`”`}{` take only the container id`}</li>
      <li parentName="ul">{`now after we took all the stopped container ids we simply remove them with the docker command.`}</li>
    </ul>
    <p>{`remove all images with :`}</p>
    <pre><code parentName="pre" {...{}}>{`docker rmi $(docker images | grep "<none>" | awk '{print $3}')
`}</code></pre>
    <p>{`how it works?`}</p>
    <ul>
      <li parentName="ul">{`”`}{` docker images `}{`”`}{` `}{`–`}{` list all images`}</li>
      <li parentName="ul">{`”`}{` grep `}{`“`}{`”`}{` `}{`”`}{` `}{`–`}{` take only the ones that contain`}</li>
      <li parentName="ul">{`”`}{` awk `}{`‘`}{`{print $3}`}{`’`}{` `}{`”`}{` `}{`–`}{` grab their ids`}</li>
      <li parentName="ul">{`now remove them with the docker command`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      